export default class ShiftPattern {
  shiftPatternId: string;
  affiliationId: string;
  shiftPatternName: string;
  abbreviation: string;
  startTime: string;
  endTime: string;
  breakTime: string;
  morningBreakTime: string;
  midnightBreakTime: string;
  daytimeActiveHour: string;
  midnightTimeFlag: string;
  overTimeFlag: string;
  nightShiftFlag: string;
  resultPriorityFlag: string;
  nightGuardFlag: string;
  enterAfterNightShiftFlag: string;
  nightTelFlag: string;
  timeZone: string;
  patternOrder: number;
  workClassificationId: string;
  unitPrice: string;
  isValid: string;
  shainConfirmation: boolean;
  opeTime: string;
  constructor(
    shiftPatternId = "",
    affiliationId = "",
    shiftPatternName = "",
    abbreviation = "",
    startTime = "",
    endTime = "",
    breakTime = "",
    morningBreakTime = "",
    midnightBreakTime = "",
    daytimeActiveHour = "",
    midnightTimeFlag = "",
    overTimeFlag = "",
    nightShiftFlag = "",
    resultPriorityFlag = "",
    nightGuardFlag = "",
    enterAfterNightShiftFlag = "",
    nightTelFlag = "",
    timeZone = "",
    patternOrder = 9999,
    workClassificationId = "",
    unitPrice = "",
    isValid = "",
    shainConfirmation = false,
    opeTime = ""
  ) {
    this.shiftPatternId = shiftPatternId;
    this.affiliationId = affiliationId;
    this.shiftPatternName = shiftPatternName;
    this.abbreviation = abbreviation;
    this.startTime = startTime;
    this.endTime = endTime;
    this.breakTime = breakTime;
    this.morningBreakTime = morningBreakTime;
    this.midnightBreakTime = midnightBreakTime;
    this.daytimeActiveHour = daytimeActiveHour;
    this.midnightTimeFlag = midnightTimeFlag;
    this.overTimeFlag = overTimeFlag;
    this.nightShiftFlag = nightShiftFlag;
    this.resultPriorityFlag = resultPriorityFlag;
    this.nightGuardFlag = nightGuardFlag;
    this.enterAfterNightShiftFlag = enterAfterNightShiftFlag;
    this.nightTelFlag = nightTelFlag;
    this.timeZone = timeZone;
    this.patternOrder = patternOrder;
    this.workClassificationId = workClassificationId;
    this.unitPrice = unitPrice;
    this.isValid = isValid;
    this.shainConfirmation = shainConfirmation;
    this.opeTime = opeTime;
  }
}
